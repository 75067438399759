$animation-timing-function: linear;

$star1-color: rgba(103, 160, 252, 0.9);
$star2-color: rgba(150, 190, 255, 0.8);
$star3-color: rgba(255, 255, 255, 0.5);

$star1-delay: 50s;
$star2-delay: 100s;
$star3-delay: 150s;

$card-shadow: rgba(64, 64, 64, 0.1);

$bp-md: 600px;
$bp-lg: 800px;

:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

main {
  position: absolute;
  width: 100%;
  height: 100%;
}

.main {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.page-content {
  display: grid;
  grid-gap: 0.5rem;
  max-width: $bp-md;
  margin: 0 auto;
  font-family: 'Source Sans Pro', sans-serif;
  @media (min-width: $bp-md) {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}