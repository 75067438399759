.stars1 {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1983px 1070px $star1-color, 1625px 734px $star1-color, 1505px 1218px $star1-color,
    1288px 1382px $star1-color, 1023px 982px $star1-color, 184px 1501px $star1-color, 1352px 1072px $star1-color,
    966px 577px $star1-color, 1928px 1505px $star1-color, 284px 1863px $star1-color, 871px 390px $star1-color,
    1591px 1017px $star1-color, 1919px 1315px $star1-color, 1552px 697px $star1-color,
    1775px 1722px $star1-color, 61px 321px $star1-color, 1108px 1281px $star1-color, 1795px 22px $star1-color,
    1808px 716px $star1-color, 797px 374px $star1-color, 796px 1149px $star1-color, 952px 1px $star1-color,
    1361px 1185px $star1-color, 1110px 1577px $star1-color, 1058px 1925px $star1-color,
    160px 1987px $star1-color, 1274px 1205px $star1-color, 39px 85px $star1-color, 1835px 484px $star1-color,
    495px 1670px $star1-color, 986px 691px $star1-color, 2px 1486px $star1-color, 719px 805px $star1-color,
    1601px 89px $star1-color, 591px 565px $star1-color, 1317px 1604px $star1-color, 1805px 1486px $star1-color,
    703px 236px $star1-color, 1950px 1089px $star1-color, 1527px 1923px $star1-color, 1690px 36px $star1-color,
    1252px 245px $star1-color, 343px 903px $star1-color, 143px 874px $star1-color, 939px 1705px $star1-color,
    1733px 372px $star1-color, 362px 830px $star1-color, 1580px 1181px $star1-color, 251px 535px $star1-color,
    1666px 1111px $star1-color, 1276px 1530px $star1-color, 958px 1989px $star1-color, 75px 987px $star1-color,
    256px 217px $star1-color, 635px 1194px $star1-color, 1004px 1546px $star1-color, 1910px 878px $star1-color,
    520px 506px $star1-color, 1000px 210px $star1-color, 1455px 1027px $star1-color, 417px 1714px $star1-color,
    1376px 674px $star1-color, 1744px 1390px $star1-color, 195px 283px $star1-color, 621px 1638px $star1-color,
    962px 997px $star1-color, 283px 1219px $star1-color, 1591px 1953px $star1-color, 160px 652px $star1-color,
    1430px 948px $star1-color, 1589px 1722px $star1-color, 614px 1305px $star1-color, 167px 342px $star1-color,
    1172px 1861px $star1-color, 1097px 1249px $star1-color, 1094px 1743px $star1-color,
    1988px 1361px $star1-color, 461px 694px $star1-color, 1096px 584px $star1-color, 1876px 1984px $star1-color,
    315px 245px $star1-color, 441px 258px $star1-color, 1219px 1887px $star1-color, 480px 245px $star1-color,
    285px 819px $star1-color, 1753px 1833px $star1-color, 816px 1780px $star1-color, 245px 1927px $star1-color,
    715px 1523px $star1-color, 475px 1595px $star1-color, 1162px 66px $star1-color, 543px 1758px $star1-color,
    112px 1847px $star1-color, 1604px 1243px $star1-color, 1888px 1758px $star1-color, 162px 1385px $star1-color,
    1587px 1863px $star1-color, 1059px 48px $star1-color, 1161px 1650px $star1-color, 1881px 289px $star1-color,
    475px 363px $star1-color, 160px 911px $star1-color, 487px 291px $star1-color, 496px 70px $star1-color,
    1107px 90px $star1-color, 731px 1771px $star1-color, 1517px 918px $star1-color, 1013px 716px $star1-color,
    1832px 882px $star1-color, 107px 393px $star1-color, 1503px 1860px $star1-color, 31px 94px $star1-color,
    1640px 1112px $star1-color, 697px 1657px $star1-color, 435px 81px $star1-color, 1526px 1467px $star1-color,
    602px 1001px $star1-color, 1409px 306px $star1-color, 1631px 1005px $star1-color, 302px 1886px $star1-color,
    1262px 522px $star1-color, 1606px 776px $star1-color, 1813px 1471px $star1-color, 1784px 1953px $star1-color,
    499px 1841px $star1-color, 805px 1191px $star1-color, 786px 864px $star1-color, 940px 1562px $star1-color,
    1016px 1362px $star1-color, 1036px 961px $star1-color, 1666px 173px $star1-color, 1214px 979px $star1-color,
    54px 1293px $star1-color, 231px 710px $star1-color, 434px 1321px $star1-color, 259px 1716px $star1-color,
    1463px 380px $star1-color, 1406px 1130px $star1-color, 913px 611px $star1-color, 1489px 1218px $star1-color,
    1777px 61px $star1-color, 967px 63px $star1-color, 1598px 1504px $star1-color, 1528px 1134px $star1-color,
    1641px 111px $star1-color, 1980px 1181px $star1-color, 945px 1698px $star1-color, 430px 1617px $star1-color,
    482px 85px $star1-color, 554px 1473px $star1-color, 1112px 614px $star1-color, 1030px 1349px $star1-color,
    1666px 1753px $star1-color, 1049px 978px $star1-color, 1817px 1144px $star1-color, 759px 1687px $star1-color,
    1371px 1720px $star1-color, 1393px 461px $star1-color, 26px 983px $star1-color, 85px 1821px $star1-color,
    1193px 321px $star1-color, 240px 64px $star1-color, 220px 973px $star1-color, 607px 312px $star1-color,
    1102px 679px $star1-color, 1314px 329px $star1-color, 1802px 1079px $star1-color, 1997px 85px $star1-color,
    782px 479px $star1-color, 1647px 52px $star1-color, 1945px 514px $star1-color, 938px 351px $star1-color,
    1140px 1813px $star1-color, 1348px 1582px $star1-color, 1188px 1188px $star1-color,
    1494px 1341px $star1-color, 1552px 834px $star1-color, 1592px 1952px $star1-color, 450px 98px $star1-color,
    581px 1213px $star1-color, 1736px 1193px $star1-color, 944px 1950px $star1-color, 526px 1044px $star1-color,
    1869px 788px $star1-color, 911px 1295px $star1-color, 1521px 1135px $star1-color, 1725px 1438px $star1-color,
    1691px 110px $star1-color, 1903px 1777px $star1-color, 643px 1826px $star1-color, 222px 1810px $star1-color,
    1613px 1035px $star1-color, 201px 24px $star1-color, 1325px 557px $star1-color, 1169px 1652px $star1-color,
    385px 290px $star1-color, 1733px 168px $star1-color, 1886px 438px $star1-color, 1533px 315px $star1-color,
    388px 960px $star1-color, 312px 1818px $star1-color, 1605px 6px $star1-color, 231px 1794px $star1-color,
    686px 1810px $star1-color, 1301px 577px $star1-color, 1583px 474px $star1-color, 1916px 756px $star1-color,
    694px 1494px $star1-color, 1877px 1341px $star1-color, 25px 6px $star1-color, 1717px 316px $star1-color,
    1906px 1307px $star1-color, 1555px 316px $star1-color, 699px 690px $star1-color, 1335px 368px $star1-color,
    1871px 668px $star1-color, 301px 492px $star1-color, 1899px 747px $star1-color, 680px 444px $star1-color,
    654px 1063px $star1-color, 45px 1797px $star1-color, 826px 281px $star1-color, 1041px 1859px $star1-color,
    1690px 7px $star1-color, 797px 1473px $star1-color, 1697px 1680px $star1-color, 1704px 572px $star1-color,
    144px 49px $star1-color, 1099px 264px $star1-color, 789px 1804px $star1-color, 186px 741px $star1-color,
    306px 1496px $star1-color, 473px 209px $star1-color, 1763px 127px $star1-color, 116px 288px $star1-color,
    450px 385px $star1-color, 210px 457px $star1-color, 1433px 1540px $star1-color, 1766px 1459px $star1-color,
    1426px 215px $star1-color, 1313px 296px $star1-color, 410px 81px $star1-color, 1809px 593px $star1-color,
    515px 216px $star1-color, 1903px 1912px $star1-color, 564px 1545px $star1-color, 99px 302px $star1-color,
    164px 1465px $star1-color, 1560px 801px $star1-color, 1839px 663px $star1-color, 191px 1903px $star1-color,
    5px 537px $star1-color, 1150px 38px $star1-color, 1310px 647px $star1-color, 1257px 555px $star1-color,
    487px 1470px $star1-color, 1769px 1121px $star1-color, 1698px 1155px $star1-color,
    1176px 1916px $star1-color, 1192px 583px $star1-color, 1778px 1511px $star1-color, 1330px 354px $star1-color,
    705px 737px $star1-color, 449px 665px $star1-color, 89px 1486px $star1-color, 751px 1974px $star1-color,
    1733px 1742px $star1-color, 1289px 1386px $star1-color, 1621px 1363px $star1-color,
    571px 1861px $star1-color, 1697px 1850px $star1-color, 504px 624px $star1-color, 889px 238px $star1-color,
    1627px 1435px $star1-color, 1379px 1367px $star1-color, 872px 550px $star1-color, 472px 532px $star1-color,
    466px 1997px $star1-color, 932px 1896px $star1-color, 1044px 83px $star1-color, 1262px 1593px $star1-color,
    1953px 1478px $star1-color, 1127px 1716px $star1-color, 325px 176px $star1-color, 1742px 1505px $star1-color,
    1088px 288px $star1-color, 1212px 472px $star1-color, 1365px 985px $star1-color, 1086px 803px $star1-color,
    256px 263px $star1-color, 1300px 1444px $star1-color, 1673px 201px $star1-color, 188px 1133px $star1-color,
    404px 483px $star1-color, 59px 1006px $star1-color, 1013px 1651px $star1-color, 947px 239px $star1-color,
    18px 178px $star1-color, 1430px 1851px $star1-color, 763px 1777px $star1-color, 1737px 196px $star1-color,
    643px 977px $star1-color, 1335px 1275px $star1-color, 1513px 1200px $star1-color, 497px 659px $star1-color,
    1929px 800px $star1-color, 1481px 283px $star1-color, 1232px 331px $star1-color, 1518px 1754px $star1-color,
    515px 869px $star1-color, 1127px 1290px $star1-color, 726px 1453px $star1-color, 864px 267px $star1-color,
    313px 1634px $star1-color, 1562px 606px $star1-color, 91px 1803px $star1-color, 40px 1789px $star1-color,
    1097px 944px $star1-color, 1949px 1949px $star1-color, 17px 1495px $star1-color, 1786px 93px $star1-color,
    577px 1866px $star1-color, 1252px 546px $star1-color, 564px 151px $star1-color, 1096px 1675px $star1-color,
    1743px 1694px $star1-color, 1332px 311px $star1-color, 1935px 1625px $star1-color, 1251px 900px $star1-color,
    840px 1126px $star1-color, 763px 669px $star1-color, 1414px 1146px $star1-color, 32px 1287px $star1-color,
    950px 739px $star1-color, 1730px 856px $star1-color, 1032px 1754px $star1-color, 155px 201px $star1-color,
    1999px 1250px $star1-color, 1811px 1488px $star1-color, 805px 281px $star1-color, 875px 1358px $star1-color,
    1757px 86px $star1-color, 1824px 605px $star1-color, 429px 453px $star1-color, 1955px 1159px $star1-color,
    19px 1874px $star1-color, 1549px 109px $star1-color, 1684px 145px $star1-color, 1973px 685px $star1-color,
    338px 839px $star1-color, 659px 46px $star1-color, 1600px 344px $star1-color, 838px 994px $star1-color,
    337px 1918px $star1-color, 583px 740px $star1-color, 687px 251px $star1-color, 1900px 1031px $star1-color,
    1296px 449px $star1-color, 1268px 592px $star1-color, 383px 1226px $star1-color, 868px 101px $star1-color,
    891px 67px $star1-color, 1957px 940px $star1-color, 74px 1445px $star1-color, 1432px 898px $star1-color,
    430px 1783px $star1-color, 954px 185px $star1-color, 359px 1455px $star1-color, 635px 1786px $star1-color,
    1186px 1259px $star1-color, 1445px 1505px $star1-color, 1177px 781px $star1-color, 1407px 603px $star1-color,
    1257px 980px $star1-color, 96px 562px $star1-color, 1999px 1560px $star1-color, 1153px 1554px $star1-color,
    1033px 672px $star1-color, 1051px 546px $star1-color, 753px 876px $star1-color, 1763px 145px $star1-color,
    1704px 1207px $star1-color, 34px 1261px $star1-color, 817px 1041px $star1-color, 247px 1674px $star1-color,
    121px 429px $star1-color, 515px 570px $star1-color, 1762px 1489px $star1-color, 234px 940px $star1-color,
    1095px 1028px $star1-color, 720px 1119px $star1-color, 1238px 334px $star1-color, 91px 113px $star1-color,
    147px 897px $star1-color, 1029px 1805px $star1-color, 1680px 1957px $star1-color, 475px 1503px $star1-color,
    1557px 917px $star1-color, 1458px 1762px $star1-color, 612px 1707px $star1-color, 1329px 393px $star1-color,
    160px 1167px $star1-color, 1631px 1887px $star1-color, 1125px 141px $star1-color, 257px 376px $star1-color,
    809px 26px $star1-color, 1349px 199px $star1-color, 1805px 88px $star1-color, 69px 1953px $star1-color,
    1206px 529px $star1-color, 646px 227px $star1-color, 1097px 1664px $star1-color, 42px 1261px $star1-color,
    1604px 360px $star1-color, 1939px 187px $star1-color, 1220px 1264px $star1-color, 1044px 995px $star1-color,
    1005px 16px $star1-color, 1648px 1612px $star1-color, 847px 1014px $star1-color, 339px 1584px $star1-color,
    1329px 1304px $star1-color, 961px 1970px $star1-color, 711px 221px $star1-color, 888px 566px $star1-color,
    53px 704px $star1-color, 1369px 846px $star1-color, 1261px 1775px $star1-color, 737px 1277px $star1-color,
    1071px 1570px $star1-color, 77px 1872px $star1-color, 1268px 1501px $star1-color, 598px 1640px $star1-color,
    1769px 297px $star1-color, 1074px 656px $star1-color, 1006px 1154px $star1-color, 672px 1969px $star1-color,
    922px 243px $star1-color, 622px 773px $star1-color, 1875px 804px $star1-color, 1873px 600px $star1-color,
    1844px 1794px $star1-color, 1253px 1995px $star1-color, 616px 1198px $star1-color,
    1829px 1743px $star1-color, 1321px 504px $star1-color, 1340px 566px $star1-color, 1520px 1825px $star1-color,
    1696px 1333px $star1-color, 537px 158px $star1-color, 1106px 162px $star1-color, 25px 514px $star1-color,
    1022px 422px $star1-color, 1955px 391px $star1-color, 1126px 1741px $star1-color, 754px 905px $star1-color,
    1217px 1696px $star1-color, 351px 270px $star1-color, 950px 1260px $star1-color, 1847px 658px $star1-color,
    23px 684px $star1-color, 1295px 572px $star1-color, 257px 1053px $star1-color, 87px 303px $star1-color,
    798px 1748px $star1-color, 1150px 142px $star1-color, 48px 1641px $star1-color, 1492px 1328px $star1-color,
    1385px 1766px $star1-color, 296px 1726px $star1-color, 1299px 1780px $star1-color, 1316px 522px $star1-color,
    1953px 1526px $star1-color, 1007px 1089px $star1-color, 1824px 1598px $star1-color,
    1720px 480px $star1-color, 555px 1942px $star1-color, 367px 432px $star1-color, 957px 1162px $star1-color,
    384px 1044px $star1-color, 738px 618px $star1-color, 1568px 1432px $star1-color, 211px 788px $star1-color,
    198px 1761px $star1-color, 745px 177px $star1-color, 1700px 159px $star1-color, 316px 498px $star1-color,
    65px 1610px $star1-color, 1948px 1638px $star1-color, 936px 1461px $star1-color, 1656px 622px $star1-color,
    575px 1437px $star1-color, 336px 1424px $star1-color, 1215px 690px $star1-color, 1641px 1542px $star1-color,
    998px 1685px $star1-color, 952px 1274px $star1-color, 1448px 1165px $star1-color, 904px 1783px $star1-color,
    1788px 1404px $star1-color, 1748px 1717px $star1-color, 793px 922px $star1-color, 1032px 355px $star1-color,
    1158px 38px $star1-color, 1573px 1855px $star1-color, 751px 1199px $star1-color, 1284px 227px $star1-color,
    391px 416px $star1-color, 55px 204px $star1-color, 962px 1918px $star1-color, 668px 1347px $star1-color,
    613px 1215px $star1-color, 615px 732px $star1-color, 1442px 1752px $star1-color, 1086px 1779px $star1-color,
    532px 320px $star1-color, 1228px 1321px $star1-color, 1796px 1906px $star1-color, 150px 715px $star1-color,
    1694px 1005px $star1-color, 1509px 391px $star1-color, 262px 303px $star1-color, 1743px 909px $star1-color,
    1966px 1203px $star1-color, 707px 1033px $star1-color, 82px 1332px $star1-color, 104px 794px $star1-color,
    246px 759px $star1-color, 507px 1380px $star1-color, 1227px 616px $star1-color, 917px 1354px $star1-color,
    1941px 759px $star1-color, 451px 852px $star1-color, 17px 131px $star1-color, 780px 476px $star1-color,
    816px 211px $star1-color, 1039px 1245px $star1-color, 1521px 1036px $star1-color, 1509px 1542px $star1-color,
    1368px 960px $star1-color, 1104px 1437px $star1-color, 1171px 976px $star1-color, 681px 1305px $star1-color,
    1741px 579px $star1-color, 953px 704px $star1-color, 1810px 328px $star1-color, 1960px 1396px $star1-color,
    1098px 295px $star1-color, 1409px 919px $star1-color, 1830px 1176px $star1-color, 492px 1063px $star1-color,
    1277px 515px $star1-color, 602px 1546px $star1-color, 1832px 236px $star1-color, 1513px 274px $star1-color,
    1983px 794px $star1-color, 2000px 1505px $star1-color, 920px 382px $star1-color, 1374px 1235px $star1-color,
    692px 310px $star1-color, 1028px 1782px $star1-color, 1007px 887px $star1-color, 1492px 858px $star1-color,
    877px 1817px $star1-color, 1024px 1942px $star1-color, 658px 82px $star1-color, 526px 742px $star1-color,
    755px 880px $star1-color, 301px 1186px $star1-color, 318px 31px $star1-color, 1517px 1244px $star1-color,
    1996px 1126px $star1-color, 464px 1861px $star1-color, 334px 674px $star1-color, 768px 1103px $star1-color,
    628px 890px $star1-color, 1054px 1534px $star1-color, 59px 1576px $star1-color, 189px 693px $star1-color,
    16px 1097px $star1-color, 563px 1186px $star1-color, 428px 523px $star1-color, 1047px 1231px $star1-color,
    1940px 1262px $star1-color, 643px 971px $star1-color, 611px 1794px $star1-color, 1676px 21px $star1-color,
    1882px 1523px $star1-color, 258px 1584px $star1-color, 768px 295px $star1-color, 1253px 1431px $star1-color,
    1855px 362px $star1-color, 576px 987px $star1-color, 1904px 470px $star1-color, 895px 1390px $star1-color,
    771px 966px $star1-color, 1034px 1815px $star1-color, 203px 1292px $star1-color, 566px 893px $star1-color,
    63px 913px $star1-color, 1340px 1416px $star1-color, 528px 967px $star1-color, 1965px 444px $star1-color,
    1961px 132px $star1-color, 1049px 577px $star1-color, 459px 1031px $star1-color, 261px 1780px $star1-color,
    1092px 1480px $star1-color, 286px 759px $star1-color, 1014px 1604px $star1-color, 144px 1461px $star1-color,
    1180px 1694px $star1-color, 1348px 613px $star1-color, 518px 1479px $star1-color, 478px 649px $star1-color,
    1303px 629px $star1-color, 1713px 258px $star1-color, 1912px 501px $star1-color, 300px 227px $star1-color,
    989px 1068px $star1-color, 197px 158px $star1-color, 244px 420px $star1-color, 889px 486px $star1-color,
    1517px 1812px $star1-color, 965px 308px $star1-color, 409px 1293px $star1-color, 47px 1400px $star1-color,
    1473px 1150px $star1-color, 311px 1711px $star1-color, 1938px 1000px $star1-color, 717px 1331px $star1-color,
    282px 881px $star1-color, 1305px 382px $star1-color, 1639px 52px $star1-color, 1466px 1228px $star1-color,
    574px 26px $star1-color, 1310px 899px $star1-color, 15px 1795px $star1-color, 1138px 941px $star1-color,
    1235px 1416px $star1-color, 27px 375px $star1-color, 1333px 1470px $star1-color, 1462px 1837px $star1-color,
    1830px 1217px $star1-color, 1521px 1848px $star1-color, 730px 515px $star1-color, 514px 1869px $star1-color,
    468px 1906px $star1-color, 1931px 259px $star1-color, 1612px 151px $star1-color, 1495px 735px $star1-color,
    710px 1096px $star1-color, 1976px 546px $star1-color, 50px 741px $star1-color, 1313px 381px $star1-color,
    54px 102px $star1-color, 1873px 1009px $star1-color, 1696px 688px $star1-color, 626px 922px $star1-color,
    244px 1438px $star1-color, 1746px 1787px $star1-color, 1824px 1437px $star1-color,
    1730px 1219px $star1-color, 785px 198px $star1-color, 1174px 931px $star1-color, 909px 517px $star1-color,
    1283px 662px $star1-color, 1074px 194px $star1-color, 847px 1945px $star1-color, 113px 427px $star1-color,
    728px 352px $star1-color, 1205px 1316px $star1-color, 786px 668px $star1-color, 64px 427px $star1-color,
    548px 1370px $star1-color, 1102px 616px $star1-color, 676px 1192px $star1-color, 1652px 1264px $star1-color,
    649px 69px $star1-color, 1593px 645px $star1-color, 1651px 816px $star1-color, 1914px 977px $star1-color,
    80px 1006px $star1-color, 1252px 742px $star1-color, 1291px 981px $star1-color, 1325px 273px $star1-color,
    831px 1581px $star1-color, 344px 1503px $star1-color, 1885px 1849px $star1-color, 745px 1030px $star1-color,
    764px 1396px $star1-color, 1241px 829px $star1-color, 1281px 160px $star1-color, 1210px 1860px $star1-color,
    1998px 411px $star1-color, 1752px 1816px $star1-color, 21px 1656px $star1-color, 1863px 1988px $star1-color,
    1860px 331px $star1-color, 79px 512px $star1-color;
  animation: animStar $star1-delay $animation-timing-function infinite;
}

.stars1:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 1983px 1070px $star1-color, 1625px 734px $star1-color, 1505px 1218px $star1-color,
    1288px 1382px $star1-color, 1023px 982px $star1-color, 184px 1501px $star1-color, 1352px 1072px $star1-color,
    966px 577px $star1-color, 1928px 1505px $star1-color, 284px 1863px $star1-color, 871px 390px $star1-color,
    1591px 1017px $star1-color, 1919px 1315px $star1-color, 1552px 697px $star1-color,
    1775px 1722px $star1-color, 61px 321px $star1-color, 1108px 1281px $star1-color, 1795px 22px $star1-color,
    1808px 716px $star1-color, 797px 374px $star1-color, 796px 1149px $star1-color, 952px 1px $star1-color,
    1361px 1185px $star1-color, 1110px 1577px $star1-color, 1058px 1925px $star1-color,
    160px 1987px $star1-color, 1274px 1205px $star1-color, 39px 85px $star1-color, 1835px 484px $star1-color,
    495px 1670px $star1-color, 986px 691px $star1-color, 2px 1486px $star1-color, 719px 805px $star1-color,
    1601px 89px $star1-color, 591px 565px $star1-color, 1317px 1604px $star1-color, 1805px 1486px $star1-color,
    703px 236px $star1-color, 1950px 1089px $star1-color, 1527px 1923px $star1-color, 1690px 36px $star1-color,
    1252px 245px $star1-color, 343px 903px $star1-color, 143px 874px $star1-color, 939px 1705px $star1-color,
    1733px 372px $star1-color, 362px 830px $star1-color, 1580px 1181px $star1-color, 251px 535px $star1-color,
    1666px 1111px $star1-color, 1276px 1530px $star1-color, 958px 1989px $star1-color, 75px 987px $star1-color,
    256px 217px $star1-color, 635px 1194px $star1-color, 1004px 1546px $star1-color, 1910px 878px $star1-color,
    520px 506px $star1-color, 1000px 210px $star1-color, 1455px 1027px $star1-color, 417px 1714px $star1-color,
    1376px 674px $star1-color, 1744px 1390px $star1-color, 195px 283px $star1-color, 621px 1638px $star1-color,
    962px 997px $star1-color, 283px 1219px $star1-color, 1591px 1953px $star1-color, 160px 652px $star1-color,
    1430px 948px $star1-color, 1589px 1722px $star1-color, 614px 1305px $star1-color, 167px 342px $star1-color,
    1172px 1861px $star1-color, 1097px 1249px $star1-color, 1094px 1743px $star1-color,
    1988px 1361px $star1-color, 461px 694px $star1-color, 1096px 584px $star1-color, 1876px 1984px $star1-color,
    315px 245px $star1-color, 441px 258px $star1-color, 1219px 1887px $star1-color, 480px 245px $star1-color,
    285px 819px $star1-color, 1753px 1833px $star1-color, 816px 1780px $star1-color, 245px 1927px $star1-color,
    715px 1523px $star1-color, 475px 1595px $star1-color, 1162px 66px $star1-color, 543px 1758px $star1-color,
    112px 1847px $star1-color, 1604px 1243px $star1-color, 1888px 1758px $star1-color, 162px 1385px $star1-color,
    1587px 1863px $star1-color, 1059px 48px $star1-color, 1161px 1650px $star1-color, 1881px 289px $star1-color,
    475px 363px $star1-color, 160px 911px $star1-color, 487px 291px $star1-color, 496px 70px $star1-color,
    1107px 90px $star1-color, 731px 1771px $star1-color, 1517px 918px $star1-color, 1013px 716px $star1-color,
    1832px 882px $star1-color, 107px 393px $star1-color, 1503px 1860px $star1-color, 31px 94px $star1-color,
    1640px 1112px $star1-color, 697px 1657px $star1-color, 435px 81px $star1-color, 1526px 1467px $star1-color,
    602px 1001px $star1-color, 1409px 306px $star1-color, 1631px 1005px $star1-color, 302px 1886px $star1-color,
    1262px 522px $star1-color, 1606px 776px $star1-color, 1813px 1471px $star1-color, 1784px 1953px $star1-color,
    499px 1841px $star1-color, 805px 1191px $star1-color, 786px 864px $star1-color, 940px 1562px $star1-color,
    1016px 1362px $star1-color, 1036px 961px $star1-color, 1666px 173px $star1-color, 1214px 979px $star1-color,
    54px 1293px $star1-color, 231px 710px $star1-color, 434px 1321px $star1-color, 259px 1716px $star1-color,
    1463px 380px $star1-color, 1406px 1130px $star1-color, 913px 611px $star1-color, 1489px 1218px $star1-color,
    1777px 61px $star1-color, 967px 63px $star1-color, 1598px 1504px $star1-color, 1528px 1134px $star1-color,
    1641px 111px $star1-color, 1980px 1181px $star1-color, 945px 1698px $star1-color, 430px 1617px $star1-color,
    482px 85px $star1-color, 554px 1473px $star1-color, 1112px 614px $star1-color, 1030px 1349px $star1-color,
    1666px 1753px $star1-color, 1049px 978px $star1-color, 1817px 1144px $star1-color, 759px 1687px $star1-color,
    1371px 1720px $star1-color, 1393px 461px $star1-color, 26px 983px $star1-color, 85px 1821px $star1-color,
    1193px 321px $star1-color, 240px 64px $star1-color, 220px 973px $star1-color, 607px 312px $star1-color,
    1102px 679px $star1-color, 1314px 329px $star1-color, 1802px 1079px $star1-color, 1997px 85px $star1-color,
    782px 479px $star1-color, 1647px 52px $star1-color, 1945px 514px $star1-color, 938px 351px $star1-color,
    1140px 1813px $star1-color, 1348px 1582px $star1-color, 1188px 1188px $star1-color,
    1494px 1341px $star1-color, 1552px 834px $star1-color, 1592px 1952px $star1-color, 450px 98px $star1-color,
    581px 1213px $star1-color, 1736px 1193px $star1-color, 944px 1950px $star1-color, 526px 1044px $star1-color,
    1869px 788px $star1-color, 911px 1295px $star1-color, 1521px 1135px $star1-color, 1725px 1438px $star1-color,
    1691px 110px $star1-color, 1903px 1777px $star1-color, 643px 1826px $star1-color, 222px 1810px $star1-color,
    1613px 1035px $star1-color, 201px 24px $star1-color, 1325px 557px $star1-color, 1169px 1652px $star1-color,
    385px 290px $star1-color, 1733px 168px $star1-color, 1886px 438px $star1-color, 1533px 315px $star1-color,
    388px 960px $star1-color, 312px 1818px $star1-color, 1605px 6px $star1-color, 231px 1794px $star1-color,
    686px 1810px $star1-color, 1301px 577px $star1-color, 1583px 474px $star1-color, 1916px 756px $star1-color,
    694px 1494px $star1-color, 1877px 1341px $star1-color, 25px 6px $star1-color, 1717px 316px $star1-color,
    1906px 1307px $star1-color, 1555px 316px $star1-color, 699px 690px $star1-color, 1335px 368px $star1-color,
    1871px 668px $star1-color, 301px 492px $star1-color, 1899px 747px $star1-color, 680px 444px $star1-color,
    654px 1063px $star1-color, 45px 1797px $star1-color, 826px 281px $star1-color, 1041px 1859px $star1-color,
    1690px 7px $star1-color, 797px 1473px $star1-color, 1697px 1680px $star1-color, 1704px 572px $star1-color,
    144px 49px $star1-color, 1099px 264px $star1-color, 789px 1804px $star1-color, 186px 741px $star1-color,
    306px 1496px $star1-color, 473px 209px $star1-color, 1763px 127px $star1-color, 116px 288px $star1-color,
    450px 385px $star1-color, 210px 457px $star1-color, 1433px 1540px $star1-color, 1766px 1459px $star1-color,
    1426px 215px $star1-color, 1313px 296px $star1-color, 410px 81px $star1-color, 1809px 593px $star1-color,
    515px 216px $star1-color, 1903px 1912px $star1-color, 564px 1545px $star1-color, 99px 302px $star1-color,
    164px 1465px $star1-color, 1560px 801px $star1-color, 1839px 663px $star1-color, 191px 1903px $star1-color,
    5px 537px $star1-color, 1150px 38px $star1-color, 1310px 647px $star1-color, 1257px 555px $star1-color,
    487px 1470px $star1-color, 1769px 1121px $star1-color, 1698px 1155px $star1-color,
    1176px 1916px $star1-color, 1192px 583px $star1-color, 1778px 1511px $star1-color, 1330px 354px $star1-color,
    705px 737px $star1-color, 449px 665px $star1-color, 89px 1486px $star1-color, 751px 1974px $star1-color,
    1733px 1742px $star1-color, 1289px 1386px $star1-color, 1621px 1363px $star1-color,
    571px 1861px $star1-color, 1697px 1850px $star1-color, 504px 624px $star1-color, 889px 238px $star1-color,
    1627px 1435px $star1-color, 1379px 1367px $star1-color, 872px 550px $star1-color, 472px 532px $star1-color,
    466px 1997px $star1-color, 932px 1896px $star1-color, 1044px 83px $star1-color, 1262px 1593px $star1-color,
    1953px 1478px $star1-color, 1127px 1716px $star1-color, 325px 176px $star1-color, 1742px 1505px $star1-color,
    1088px 288px $star1-color, 1212px 472px $star1-color, 1365px 985px $star1-color, 1086px 803px $star1-color,
    256px 263px $star1-color, 1300px 1444px $star1-color, 1673px 201px $star1-color, 188px 1133px $star1-color,
    404px 483px $star1-color, 59px 1006px $star1-color, 1013px 1651px $star1-color, 947px 239px $star1-color,
    18px 178px $star1-color, 1430px 1851px $star1-color, 763px 1777px $star1-color, 1737px 196px $star1-color,
    643px 977px $star1-color, 1335px 1275px $star1-color, 1513px 1200px $star1-color, 497px 659px $star1-color,
    1929px 800px $star1-color, 1481px 283px $star1-color, 1232px 331px $star1-color, 1518px 1754px $star1-color,
    515px 869px $star1-color, 1127px 1290px $star1-color, 726px 1453px $star1-color, 864px 267px $star1-color,
    313px 1634px $star1-color, 1562px 606px $star1-color, 91px 1803px $star1-color, 40px 1789px $star1-color,
    1097px 944px $star1-color, 1949px 1949px $star1-color, 17px 1495px $star1-color, 1786px 93px $star1-color,
    577px 1866px $star1-color, 1252px 546px $star1-color, 564px 151px $star1-color, 1096px 1675px $star1-color,
    1743px 1694px $star1-color, 1332px 311px $star1-color, 1935px 1625px $star1-color, 1251px 900px $star1-color,
    840px 1126px $star1-color, 763px 669px $star1-color, 1414px 1146px $star1-color, 32px 1287px $star1-color,
    950px 739px $star1-color, 1730px 856px $star1-color, 1032px 1754px $star1-color, 155px 201px $star1-color,
    1999px 1250px $star1-color, 1811px 1488px $star1-color, 805px 281px $star1-color, 875px 1358px $star1-color,
    1757px 86px $star1-color, 1824px 605px $star1-color, 429px 453px $star1-color, 1955px 1159px $star1-color,
    19px 1874px $star1-color, 1549px 109px $star1-color, 1684px 145px $star1-color, 1973px 685px $star1-color,
    338px 839px $star1-color, 659px 46px $star1-color, 1600px 344px $star1-color, 838px 994px $star1-color,
    337px 1918px $star1-color, 583px 740px $star1-color, 687px 251px $star1-color, 1900px 1031px $star1-color,
    1296px 449px $star1-color, 1268px 592px $star1-color, 383px 1226px $star1-color, 868px 101px $star1-color,
    891px 67px $star1-color, 1957px 940px $star1-color, 74px 1445px $star1-color, 1432px 898px $star1-color,
    430px 1783px $star1-color, 954px 185px $star1-color, 359px 1455px $star1-color, 635px 1786px $star1-color,
    1186px 1259px $star1-color, 1445px 1505px $star1-color, 1177px 781px $star1-color, 1407px 603px $star1-color,
    1257px 980px $star1-color, 96px 562px $star1-color, 1999px 1560px $star1-color, 1153px 1554px $star1-color,
    1033px 672px $star1-color, 1051px 546px $star1-color, 753px 876px $star1-color, 1763px 145px $star1-color,
    1704px 1207px $star1-color, 34px 1261px $star1-color, 817px 1041px $star1-color, 247px 1674px $star1-color,
    121px 429px $star1-color, 515px 570px $star1-color, 1762px 1489px $star1-color, 234px 940px $star1-color,
    1095px 1028px $star1-color, 720px 1119px $star1-color, 1238px 334px $star1-color, 91px 113px $star1-color,
    147px 897px $star1-color, 1029px 1805px $star1-color, 1680px 1957px $star1-color, 475px 1503px $star1-color,
    1557px 917px $star1-color, 1458px 1762px $star1-color, 612px 1707px $star1-color, 1329px 393px $star1-color,
    160px 1167px $star1-color, 1631px 1887px $star1-color, 1125px 141px $star1-color, 257px 376px $star1-color,
    809px 26px $star1-color, 1349px 199px $star1-color, 1805px 88px $star1-color, 69px 1953px $star1-color,
    1206px 529px $star1-color, 646px 227px $star1-color, 1097px 1664px $star1-color, 42px 1261px $star1-color,
    1604px 360px $star1-color, 1939px 187px $star1-color, 1220px 1264px $star1-color, 1044px 995px $star1-color,
    1005px 16px $star1-color, 1648px 1612px $star1-color, 847px 1014px $star1-color, 339px 1584px $star1-color,
    1329px 1304px $star1-color, 961px 1970px $star1-color, 711px 221px $star1-color, 888px 566px $star1-color,
    53px 704px $star1-color, 1369px 846px $star1-color, 1261px 1775px $star1-color, 737px 1277px $star1-color,
    1071px 1570px $star1-color, 77px 1872px $star1-color, 1268px 1501px $star1-color, 598px 1640px $star1-color,
    1769px 297px $star1-color, 1074px 656px $star1-color, 1006px 1154px $star1-color, 672px 1969px $star1-color,
    922px 243px $star1-color, 622px 773px $star1-color, 1875px 804px $star1-color, 1873px 600px $star1-color,
    1844px 1794px $star1-color, 1253px 1995px $star1-color, 616px 1198px $star1-color,
    1829px 1743px $star1-color, 1321px 504px $star1-color, 1340px 566px $star1-color, 1520px 1825px $star1-color,
    1696px 1333px $star1-color, 537px 158px $star1-color, 1106px 162px $star1-color, 25px 514px $star1-color,
    1022px 422px $star1-color, 1955px 391px $star1-color, 1126px 1741px $star1-color, 754px 905px $star1-color,
    1217px 1696px $star1-color, 351px 270px $star1-color, 950px 1260px $star1-color, 1847px 658px $star1-color,
    23px 684px $star1-color, 1295px 572px $star1-color, 257px 1053px $star1-color, 87px 303px $star1-color,
    798px 1748px $star1-color, 1150px 142px $star1-color, 48px 1641px $star1-color, 1492px 1328px $star1-color,
    1385px 1766px $star1-color, 296px 1726px $star1-color, 1299px 1780px $star1-color, 1316px 522px $star1-color,
    1953px 1526px $star1-color, 1007px 1089px $star1-color, 1824px 1598px $star1-color,
    1720px 480px $star1-color, 555px 1942px $star1-color, 367px 432px $star1-color, 957px 1162px $star1-color,
    384px 1044px $star1-color, 738px 618px $star1-color, 1568px 1432px $star1-color, 211px 788px $star1-color,
    198px 1761px $star1-color, 745px 177px $star1-color, 1700px 159px $star1-color, 316px 498px $star1-color,
    65px 1610px $star1-color, 1948px 1638px $star1-color, 936px 1461px $star1-color, 1656px 622px $star1-color,
    575px 1437px $star1-color, 336px 1424px $star1-color, 1215px 690px $star1-color, 1641px 1542px $star1-color,
    998px 1685px $star1-color, 952px 1274px $star1-color, 1448px 1165px $star1-color, 904px 1783px $star1-color,
    1788px 1404px $star1-color, 1748px 1717px $star1-color, 793px 922px $star1-color, 1032px 355px $star1-color,
    1158px 38px $star1-color, 1573px 1855px $star1-color, 751px 1199px $star1-color, 1284px 227px $star1-color,
    391px 416px $star1-color, 55px 204px $star1-color, 962px 1918px $star1-color, 668px 1347px $star1-color,
    613px 1215px $star1-color, 615px 732px $star1-color, 1442px 1752px $star1-color, 1086px 1779px $star1-color,
    532px 320px $star1-color, 1228px 1321px $star1-color, 1796px 1906px $star1-color, 150px 715px $star1-color,
    1694px 1005px $star1-color, 1509px 391px $star1-color, 262px 303px $star1-color, 1743px 909px $star1-color,
    1966px 1203px $star1-color, 707px 1033px $star1-color, 82px 1332px $star1-color, 104px 794px $star1-color,
    246px 759px $star1-color, 507px 1380px $star1-color, 1227px 616px $star1-color, 917px 1354px $star1-color,
    1941px 759px $star1-color, 451px 852px $star1-color, 17px 131px $star1-color, 780px 476px $star1-color,
    816px 211px $star1-color, 1039px 1245px $star1-color, 1521px 1036px $star1-color, 1509px 1542px $star1-color,
    1368px 960px $star1-color, 1104px 1437px $star1-color, 1171px 976px $star1-color, 681px 1305px $star1-color,
    1741px 579px $star1-color, 953px 704px $star1-color, 1810px 328px $star1-color, 1960px 1396px $star1-color,
    1098px 295px $star1-color, 1409px 919px $star1-color, 1830px 1176px $star1-color, 492px 1063px $star1-color,
    1277px 515px $star1-color, 602px 1546px $star1-color, 1832px 236px $star1-color, 1513px 274px $star1-color,
    1983px 794px $star1-color, 2000px 1505px $star1-color, 920px 382px $star1-color, 1374px 1235px $star1-color,
    692px 310px $star1-color, 1028px 1782px $star1-color, 1007px 887px $star1-color, 1492px 858px $star1-color,
    877px 1817px $star1-color, 1024px 1942px $star1-color, 658px 82px $star1-color, 526px 742px $star1-color,
    755px 880px $star1-color, 301px 1186px $star1-color, 318px 31px $star1-color, 1517px 1244px $star1-color,
    1996px 1126px $star1-color, 464px 1861px $star1-color, 334px 674px $star1-color, 768px 1103px $star1-color,
    628px 890px $star1-color, 1054px 1534px $star1-color, 59px 1576px $star1-color, 189px 693px $star1-color,
    16px 1097px $star1-color, 563px 1186px $star1-color, 428px 523px $star1-color, 1047px 1231px $star1-color,
    1940px 1262px $star1-color, 643px 971px $star1-color, 611px 1794px $star1-color, 1676px 21px $star1-color,
    1882px 1523px $star1-color, 258px 1584px $star1-color, 768px 295px $star1-color, 1253px 1431px $star1-color,
    1855px 362px $star1-color, 576px 987px $star1-color, 1904px 470px $star1-color, 895px 1390px $star1-color,
    771px 966px $star1-color, 1034px 1815px $star1-color, 203px 1292px $star1-color, 566px 893px $star1-color,
    63px 913px $star1-color, 1340px 1416px $star1-color, 528px 967px $star1-color, 1965px 444px $star1-color,
    1961px 132px $star1-color, 1049px 577px $star1-color, 459px 1031px $star1-color, 261px 1780px $star1-color,
    1092px 1480px $star1-color, 286px 759px $star1-color, 1014px 1604px $star1-color, 144px 1461px $star1-color,
    1180px 1694px $star1-color, 1348px 613px $star1-color, 518px 1479px $star1-color, 478px 649px $star1-color,
    1303px 629px $star1-color, 1713px 258px $star1-color, 1912px 501px $star1-color, 300px 227px $star1-color,
    989px 1068px $star1-color, 197px 158px $star1-color, 244px 420px $star1-color, 889px 486px $star1-color,
    1517px 1812px $star1-color, 965px 308px $star1-color, 409px 1293px $star1-color, 47px 1400px $star1-color,
    1473px 1150px $star1-color, 311px 1711px $star1-color, 1938px 1000px $star1-color, 717px 1331px $star1-color,
    282px 881px $star1-color, 1305px 382px $star1-color, 1639px 52px $star1-color, 1466px 1228px $star1-color,
    574px 26px $star1-color, 1310px 899px $star1-color, 15px 1795px $star1-color, 1138px 941px $star1-color,
    1235px 1416px $star1-color, 27px 375px $star1-color, 1333px 1470px $star1-color, 1462px 1837px $star1-color,
    1830px 1217px $star1-color, 1521px 1848px $star1-color, 730px 515px $star1-color, 514px 1869px $star1-color,
    468px 1906px $star1-color, 1931px 259px $star1-color, 1612px 151px $star1-color, 1495px 735px $star1-color,
    710px 1096px $star1-color, 1976px 546px $star1-color, 50px 741px $star1-color, 1313px 381px $star1-color,
    54px 102px $star1-color, 1873px 1009px $star1-color, 1696px 688px $star1-color, 626px 922px $star1-color,
    244px 1438px $star1-color, 1746px 1787px $star1-color, 1824px 1437px $star1-color,
    1730px 1219px $star1-color, 785px 198px $star1-color, 1174px 931px $star1-color, 909px 517px $star1-color,
    1283px 662px $star1-color, 1074px 194px $star1-color, 847px 1945px $star1-color, 113px 427px $star1-color,
    728px 352px $star1-color, 1205px 1316px $star1-color, 786px 668px $star1-color, 64px 427px $star1-color,
    548px 1370px $star1-color, 1102px 616px $star1-color, 676px 1192px $star1-color, 1652px 1264px $star1-color,
    649px 69px $star1-color, 1593px 645px $star1-color, 1651px 816px $star1-color, 1914px 977px $star1-color,
    80px 1006px $star1-color, 1252px 742px $star1-color, 1291px 981px $star1-color, 1325px 273px $star1-color,
    831px 1581px $star1-color, 344px 1503px $star1-color, 1885px 1849px $star1-color, 745px 1030px $star1-color,
    764px 1396px $star1-color, 1241px 829px $star1-color, 1281px 160px $star1-color, 1210px 1860px $star1-color,
    1998px 411px $star1-color, 1752px 1816px $star1-color, 21px 1656px $star1-color, 1863px 1988px $star1-color,
    1860px 331px $star1-color, 79px 512px $star1-color;
}

.stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1124px 1500px $star2-color, 916px 1039px $star2-color, 1281px 2000px $star2-color,
    1702px 518px $star2-color, 246px 953px $star2-color, 1889px 287px $star2-color, 1438px 1139px $star2-color,
    1824px 1739px $star2-color, 1090px 1205px $star2-color, 1514px 392px $star2-color, 87px 1797px $star2-color,
    342px 25px $star2-color, 134px 1624px $star2-color, 1246px 426px $star2-color, 453px 559px $star2-color,
    609px 308px $star2-color, 506px 972px $star2-color, 684px 733px $star2-color, 85px 1641px $star2-color,
    1576px 320px $star2-color, 145px 809px $star2-color, 1822px 1000px $star2-color, 21px 1512px $star2-color,
    173px 1775px $star2-color, 1283px 1564px $star2-color, 224px 1353px $star2-color, 451px 785px $star2-color,
    703px 1983px $star2-color, 508px 1345px $star2-color, 957px 1209px $star2-color, 698px 162px $star2-color,
    159px 1835px $star2-color, 712px 227px $star2-color, 1234px 295px $star2-color, 310px 958px $star2-color,
    889px 31px $star2-color, 1321px 1156px $star2-color, 501px 274px $star2-color, 914px 96px $star2-color,
    1637px 1954px $star2-color, 774px 1326px $star2-color, 304px 550px $star2-color, 1249px 425px $star2-color,
    649px 1851px $star2-color, 723px 1114px $star2-color, 1115px 1524px $star2-color, 1937px 1932px $star2-color,
    44px 1353px $star2-color, 850px 1872px $star2-color, 1175px 1628px $star2-color, 318px 248px $star2-color,
    291px 521px $star2-color, 1246px 1707px $star2-color, 1640px 1608px $star2-color, 1961px 343px $star2-color,
    964px 1144px $star2-color, 1729px 361px $star2-color, 1493px 799px $star2-color, 1503px 1030px $star2-color,
    426px 1162px $star2-color, 991px 1700px $star2-color, 697px 630px $star2-color, 897px 1692px $star2-color,
    457px 1816px $star2-color, 667px 729px $star2-color, 1753px 1618px $star2-color, 1132px 1095px $star2-color,
    954px 1846px $star2-color, 1938px 1179px $star2-color, 135px 434px $star2-color, 1134px 1216px $star2-color,
    491px 543px $star2-color, 525px 1114px $star2-color, 1769px 1830px $star2-color, 1641px 1422px $star2-color,
    873px 548px $star2-color, 1519px 1249px $star2-color, 934px 1084px $star2-color, 753px 1528px $star2-color,
    1139px 478px $star2-color, 1533px 356px $star2-color, 264px 1956px $star2-color, 899px 487px $star2-color,
    1932px 1084px $star2-color, 1846px 64px $star2-color, 46px 1255px $star2-color, 102px 1775px $star2-color,
    678px 1579px $star2-color, 1406px 368px $star2-color, 1808px 32px $star2-color, 741px 775px $star2-color,
    225px 1338px $star2-color, 124px 731px $star2-color, 411px 135px $star2-color, 1640px 1276px $star2-color,
    54px 1998px $star2-color, 1126px 1912px $star2-color, 1896px 1754px $star2-color, 819px 827px $star2-color,
    1063px 125px $star2-color, 955px 1357px $star2-color, 945px 1808px $star2-color, 777px 1591px $star2-color,
    508px 1351px $star2-color, 966px 983px $star2-color, 168px 9px $star2-color, 1700px 533px $star2-color,
    785px 1700px $star2-color, 558px 749px $star2-color, 56px 1463px $star2-color, 1482px 371px $star2-color,
    1201px 1930px $star2-color, 207px 240px $star2-color, 538px 146px $star2-color, 893px 1156px $star2-color,
    662px 973px $star2-color, 1126px 641px $star2-color, 354px 1792px $star2-color, 1483px 1367px $star2-color,
    1032px 1516px $star2-color, 322px 1338px $star2-color, 1804px 1170px $star2-color, 1899px 561px $star2-color,
    698px 1947px $star2-color, 1377px 1501px $star2-color, 1966px 1196px $star2-color, 108px 1889px $star2-color,
    790px 1309px $star2-color, 1206px 42px $star2-color, 1903px 373px $star2-color, 465px 113px $star2-color,
    1776px 1878px $star2-color, 1363px 639px $star2-color, 1142px 1969px $star2-color, 1427px 840px $star2-color,
    212px 1412px $star2-color, 1333px 1905px $star2-color, 817px 261px $star2-color, 1590px 1046px $star2-color,
    1139px 89px $star2-color, 1852px 311px $star2-color, 1618px 1155px $star2-color, 387px 1975px $star2-color,
    290px 1660px $star2-color, 1094px 957px $star2-color, 1145px 1157px $star2-color, 825px 1179px $star2-color,
    1456px 1133px $star2-color, 1214px 922px $star2-color, 290px 1880px $star2-color, 852px 1027px $star2-color,
    275px 557px $star2-color, 1115px 724px $star2-color, 1713px 1698px $star2-color, 1819px 1196px $star2-color,
    1778px 179px $star2-color, 401px 610px $star2-color, 1288px 1286px $star2-color, 1603px 493px $star2-color,
    497px 306px $star2-color, 457px 1005px $star2-color, 675px 1814px $star2-color, 850px 1719px $star2-color,
    467px 932px $star2-color, 1312px 1366px $star2-color, 812px 1643px $star2-color, 356px 1170px $star2-color,
    1915px 787px $star2-color, 1923px 1453px $star2-color, 542px 860px $star2-color, 1496px 832px $star2-color,
    1689px 90px $star2-color, 1404px 73px $star2-color, 991px 357px $star2-color, 1534px 1469px $star2-color,
    1036px 822px $star2-color, 595px 819px $star2-color, 432px 1908px $star2-color, 221px 1519px $star2-color,
    1744px 1507px $star2-color, 505px 564px $star2-color, 1220px 1959px $star2-color, 544px 1880px $star2-color,
    744px 516px $star2-color, 547px 307px $star2-color, 1862px 247px $star2-color, 1560px 410px $star2-color,
    736px 282px $star2-color, 986px 1452px $star2-color, 808px 1942px $star2-color, 194px 1307px $star2-color,
    639px 842px $star2-color, 717px 272px $star2-color, 580px 103px $star2-color, 566px 1916px $star2-color,
    1847px 91px $star2-color, 1583px 629px $star2-color, 1270px 1517px $star2-color, 1452px 1905px $star2-color,
    1161px 1965px $star2-color;
  animation: animStar $star2-delay $animation-timing-function infinite;
}

.stars2:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 1124px 1500px $star2-color, 916px 1039px $star2-color, 1281px 2000px $star2-color,
    1702px 518px $star2-color, 246px 953px $star2-color, 1889px 287px $star2-color, 1438px 1139px $star2-color,
    1824px 1739px $star2-color, 1090px 1205px $star2-color, 1514px 392px $star2-color, 87px 1797px $star2-color,
    342px 25px $star2-color, 134px 1624px $star2-color, 1246px 426px $star2-color, 453px 559px $star2-color,
    609px 308px $star2-color, 506px 972px $star2-color, 684px 733px $star2-color, 85px 1641px $star2-color,
    1576px 320px $star2-color, 145px 809px $star2-color, 1822px 1000px $star2-color, 21px 1512px $star2-color,
    173px 1775px $star2-color, 1283px 1564px $star2-color, 224px 1353px $star2-color, 451px 785px $star2-color,
    703px 1983px $star2-color, 508px 1345px $star2-color, 957px 1209px $star2-color, 698px 162px $star2-color,
    159px 1835px $star2-color, 712px 227px $star2-color, 1234px 295px $star2-color, 310px 958px $star2-color,
    889px 31px $star2-color, 1321px 1156px $star2-color, 501px 274px $star2-color, 914px 96px $star2-color,
    1637px 1954px $star2-color, 774px 1326px $star2-color, 304px 550px $star2-color, 1249px 425px $star2-color,
    649px 1851px $star2-color, 723px 1114px $star2-color, 1115px 1524px $star2-color, 1937px 1932px $star2-color,
    44px 1353px $star2-color, 850px 1872px $star2-color, 1175px 1628px $star2-color, 318px 248px $star2-color,
    291px 521px $star2-color, 1246px 1707px $star2-color, 1640px 1608px $star2-color, 1961px 343px $star2-color,
    964px 1144px $star2-color, 1729px 361px $star2-color, 1493px 799px $star2-color, 1503px 1030px $star2-color,
    426px 1162px $star2-color, 991px 1700px $star2-color, 697px 630px $star2-color, 897px 1692px $star2-color,
    457px 1816px $star2-color, 667px 729px $star2-color, 1753px 1618px $star2-color, 1132px 1095px $star2-color,
    954px 1846px $star2-color, 1938px 1179px $star2-color, 135px 434px $star2-color, 1134px 1216px $star2-color,
    491px 543px $star2-color, 525px 1114px $star2-color, 1769px 1830px $star2-color, 1641px 1422px $star2-color,
    873px 548px $star2-color, 1519px 1249px $star2-color, 934px 1084px $star2-color, 753px 1528px $star2-color,
    1139px 478px $star2-color, 1533px 356px $star2-color, 264px 1956px $star2-color, 899px 487px $star2-color,
    1932px 1084px $star2-color, 1846px 64px $star2-color, 46px 1255px $star2-color, 102px 1775px $star2-color,
    678px 1579px $star2-color, 1406px 368px $star2-color, 1808px 32px $star2-color, 741px 775px $star2-color,
    225px 1338px $star2-color, 124px 731px $star2-color, 411px 135px $star2-color, 1640px 1276px $star2-color,
    54px 1998px $star2-color, 1126px 1912px $star2-color, 1896px 1754px $star2-color, 819px 827px $star2-color,
    1063px 125px $star2-color, 955px 1357px $star2-color, 945px 1808px $star2-color, 777px 1591px $star2-color,
    508px 1351px $star2-color, 966px 983px $star2-color, 168px 9px $star2-color, 1700px 533px $star2-color,
    785px 1700px $star2-color, 558px 749px $star2-color, 56px 1463px $star2-color, 1482px 371px $star2-color,
    1201px 1930px $star2-color, 207px 240px $star2-color, 538px 146px $star2-color, 893px 1156px $star2-color,
    662px 973px $star2-color, 1126px 641px $star2-color, 354px 1792px $star2-color, 1483px 1367px $star2-color,
    1032px 1516px $star2-color, 322px 1338px $star2-color, 1804px 1170px $star2-color, 1899px 561px $star2-color,
    698px 1947px $star2-color, 1377px 1501px $star2-color, 1966px 1196px $star2-color, 108px 1889px $star2-color,
    790px 1309px $star2-color, 1206px 42px $star2-color, 1903px 373px $star2-color, 465px 113px $star2-color,
    1776px 1878px $star2-color, 1363px 639px $star2-color, 1142px 1969px $star2-color, 1427px 840px $star2-color,
    212px 1412px $star2-color, 1333px 1905px $star2-color, 817px 261px $star2-color, 1590px 1046px $star2-color,
    1139px 89px $star2-color, 1852px 311px $star2-color, 1618px 1155px $star2-color, 387px 1975px $star2-color,
    290px 1660px $star2-color, 1094px 957px $star2-color, 1145px 1157px $star2-color, 825px 1179px $star2-color,
    1456px 1133px $star2-color, 1214px 922px $star2-color, 290px 1880px $star2-color, 852px 1027px $star2-color,
    275px 557px $star2-color, 1115px 724px $star2-color, 1713px 1698px $star2-color, 1819px 1196px $star2-color,
    1778px 179px $star2-color, 401px 610px $star2-color, 1288px 1286px $star2-color, 1603px 493px $star2-color,
    497px 306px $star2-color, 457px 1005px $star2-color, 675px 1814px $star2-color, 850px 1719px $star2-color,
    467px 932px $star2-color, 1312px 1366px $star2-color, 812px 1643px $star2-color, 356px 1170px $star2-color,
    1915px 787px $star2-color, 1923px 1453px $star2-color, 542px 860px $star2-color, 1496px 832px $star2-color,
    1689px 90px $star2-color, 1404px 73px $star2-color, 991px 357px $star2-color, 1534px 1469px $star2-color,
    1036px 822px $star2-color, 595px 819px $star2-color, 432px 1908px $star2-color, 221px 1519px $star2-color,
    1744px 1507px $star2-color, 505px 564px $star2-color, 1220px 1959px $star2-color, 544px 1880px $star2-color,
    744px 516px $star2-color, 547px 307px $star2-color, 1862px 247px $star2-color, 1560px 410px $star2-color,
    736px 282px $star2-color, 986px 1452px $star2-color, 808px 1942px $star2-color, 194px 1307px $star2-color,
    639px 842px $star2-color, 717px 272px $star2-color, 580px 103px $star2-color, 566px 1916px $star2-color,
    1847px 91px $star2-color, 1583px 629px $star2-color, 1270px 1517px $star2-color, 1452px 1905px $star2-color,
    1161px 1965px $star2-color;
}

.stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1106px 1979px $star3-color, 1572px 505px $star3-color, 25px 1324px $star3-color,
    650px 558px $star3-color, 558px 1555px $star3-color, 782px 1910px $star3-color, 95px 1646px $star3-color,
    1794px 169px $star3-color, 1104px 1373px $star3-color, 965px 1386px $star3-color, 1818px 948px $star3-color,
    1848px 760px $star3-color, 1789px 937px $star3-color, 761px 1565px $star3-color, 945px 1788px $star3-color,
    1553px 93px $star3-color, 1344px 281px $star3-color, 832px 138px $star3-color, 819px 412px $star3-color,
    386px 1164px $star3-color, 36px 1157px $star3-color, 1150px 651px $star3-color, 63px 1824px $star3-color,
    338px 202px $star3-color, 1203px 764px $star3-color, 1825px 1211px $star3-color, 204px 1892px $star3-color,
    228px 313px $star3-color, 1511px 783px $star3-color, 497px 522px $star3-color, 744px 96px $star3-color,
    511px 1254px $star3-color, 179px 1572px $star3-color, 988px 411px $star3-color, 1539px 1119px $star3-color,
    687px 1122px $star3-color, 1615px 475px $star3-color, 512px 399px $star3-color, 816px 1040px $star3-color,
    341px 103px $star3-color, 450px 505px $star3-color, 1161px 742px $star3-color, 1926px 1476px $star3-color,
    764px 1893px $star3-color, 995px 1047px $star3-color, 572px 593px $star3-color, 143px 499px $star3-color,
    963px 861px $star3-color, 575px 178px $star3-color, 1492px 424px $star3-color, 1890px 1467px $star3-color,
    612px 1453px $star3-color, 573px 346px $star3-color, 1151px 945px $star3-color, 107px 45px $star3-color,
    345px 593px $star3-color, 1659px 456px $star3-color, 1984px 1612px $star3-color, 1103px 350px $star3-color,
    696px 908px $star3-color, 1227px 1093px $star3-color, 1525px 1198px $star3-color, 294px 648px $star3-color,
    955px 378px $star3-color, 1553px 114px $star3-color, 1355px 1009px $star3-color, 911px 1568px $star3-color,
    1807px 27px $star3-color, 933px 1952px $star3-color, 493px 1501px $star3-color, 1573px 1849px $star3-color,
    962px 471px $star3-color, 1078px 1745px $star3-color, 711px 381px $star3-color, 1617px 574px $star3-color,
    666px 1277px $star3-color, 1589px 1435px $star3-color, 41px 21px $star3-color, 1427px 252px $star3-color,
    748px 351px $star3-color, 1826px 261px $star3-color, 730px 1705px $star3-color, 30px 459px $star3-color,
    953px 1573px $star3-color, 725px 1653px $star3-color, 1628px 1144px $star3-color, 1070px 1879px $star3-color,
    362px 603px $star3-color, 605px 714px $star3-color, 749px 1979px $star3-color, 1569px 1753px $star3-color,
    335px 491px $star3-color, 1618px 1237px $star3-color, 362px 1735px $star3-color, 105px 489px $star3-color,
    806px 836px $star3-color, 1523px 781px $star3-color, 411px 1405px $star3-color, 858px 220px $star3-color,
    273px 89px $star3-color;
  animation: animStar $star3-delay $animation-timing-function infinite;
}

.stars3:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 1106px 1979px $star3-color, 1572px 505px $star3-color, 25px 1324px $star3-color,
    650px 558px $star3-color, 558px 1555px $star3-color, 782px 1910px $star3-color, 95px 1646px $star3-color,
    1794px 169px $star3-color, 1104px 1373px $star3-color, 965px 1386px $star3-color, 1818px 948px $star3-color,
    1848px 760px $star3-color, 1789px 937px $star3-color, 761px 1565px $star3-color, 945px 1788px $star3-color,
    1553px 93px $star3-color, 1344px 281px $star3-color, 832px 138px $star3-color, 819px 412px $star3-color,
    386px 1164px $star3-color, 36px 1157px $star3-color, 1150px 651px $star3-color, 63px 1824px $star3-color,
    338px 202px $star3-color, 1203px 764px $star3-color, 1825px 1211px $star3-color, 204px 1892px $star3-color,
    228px 313px $star3-color, 1511px 783px $star3-color, 497px 522px $star3-color, 744px 96px $star3-color,
    511px 1254px $star3-color, 179px 1572px $star3-color, 988px 411px $star3-color, 1539px 1119px $star3-color,
    687px 1122px $star3-color, 1615px 475px $star3-color, 512px 399px $star3-color, 816px 1040px $star3-color,
    341px 103px $star3-color, 450px 505px $star3-color, 1161px 742px $star3-color, 1926px 1476px $star3-color,
    764px 1893px $star3-color, 995px 1047px $star3-color, 572px 593px $star3-color, 143px 499px $star3-color,
    963px 861px $star3-color, 575px 178px $star3-color, 1492px 424px $star3-color, 1890px 1467px $star3-color,
    612px 1453px $star3-color, 573px 346px $star3-color, 1151px 945px $star3-color, 107px 45px $star3-color,
    345px 593px $star3-color, 1659px 456px $star3-color, 1984px 1612px $star3-color, 1103px 350px $star3-color,
    696px 908px $star3-color, 1227px 1093px $star3-color, 1525px 1198px $star3-color, 294px 648px $star3-color,
    955px 378px $star3-color, 1553px 114px $star3-color, 1355px 1009px $star3-color, 911px 1568px $star3-color,
    1807px 27px $star3-color, 933px 1952px $star3-color, 493px 1501px $star3-color, 1573px 1849px $star3-color,
    962px 471px $star3-color, 1078px 1745px $star3-color, 711px 381px $star3-color, 1617px 574px $star3-color,
    666px 1277px $star3-color, 1589px 1435px $star3-color, 41px 21px $star3-color, 1427px 252px $star3-color,
    748px 351px $star3-color, 1826px 261px $star3-color, 730px 1705px $star3-color, 30px 459px $star3-color,
    953px 1573px $star3-color, 725px 1653px $star3-color, 1628px 1144px $star3-color, 1070px 1879px $star3-color,
    362px 603px $star3-color, 605px 714px $star3-color, 749px 1979px $star3-color, 1569px 1753px $star3-color,
    335px 491px $star3-color, 1618px 1237px $star3-color, 362px 1735px $star3-color, 105px 489px $star3-color,
    806px 836px $star3-color, 1523px 781px $star3-color, 411px 1405px $star3-color, 858px 220px $star3-color,
    273px 89px $star3-color;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}